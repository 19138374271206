import React, { useState } from 'react';

import IconOnlyButton from 'components/Buttons/IconOnlyButton';
import Button from 'components/Buttons/Button';
import Modal from './Modal/Modal';

type InfoButtonWithModalProps = {
  triggerButtonAriaLabel?: string;
  modalText: string;
};

const InfoButtonWithModal: React.FC<InfoButtonWithModalProps> = ({
  triggerButtonAriaLabel = 'More information',
  modalText,
}) => {
  const [showModal, setShowModal] = useState(false);
  return (
    <>
      <IconOnlyButton
        sIcon='info'
        sAriaLabel={triggerButtonAriaLabel}
        fnHandleClick={() => setShowModal(true)}
        sFlavor='tertiary'
      />
      {showModal && (
        <Modal fnSetShowModal={setShowModal} sModalDetail={modalText}>
          <Button sFlavor='secondary' fnHandleClick={() => setShowModal(false)}>
            Back
          </Button>
        </Modal>
      )}
    </>
  );
};

export default InfoButtonWithModal;
