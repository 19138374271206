import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import currency from 'currency.js';

import {
  selectDeductiblePledges,
  selectNonDeductiblePledges,
  selectPayablePledges,
} from '../../redux/selectors';
import {
  calculateCurrentDuePayablePledges,
  calculateTotalBalancePayablePledges,
  calculatePendingBalance,
} from '../helpers/calculateSelectPaymentTotals';

export type PledgePaymentAmounts = {
  currentDue: currency | null;
  currentDeductible: currency | null;
  currentNonDeductible: currency | null;
  totalBalance: currency | null;
  totalDeductible: currency | null;
  totalNonDeductible: currency | null;
  pendingAmount: currency | null;
};

const usePledgePaymentAmounts = () => {
  const allPayablePledges = useSelector(selectPayablePledges);
  const deductiblePledges = useSelector(selectDeductiblePledges);
  const nonDeductiblePledges = useSelector(selectNonDeductiblePledges);
  const [amounts, setAmounts] = useState<PledgePaymentAmounts>({
    currentDue: null,
    currentDeductible: null,
    currentNonDeductible: null,
    totalBalance: null,
    totalDeductible: null,
    totalNonDeductible: null,
    pendingAmount: null,
  });

  // Calculate pending amount
  useEffect(() => {
    if (allPayablePledges.length === 0) {
      return;
    }
    const oCalculatedPendingAmount = calculatePendingBalance(allPayablePledges);
    setAmounts((prevAmounts: PledgePaymentAmounts) => ({
      ...prevAmounts,
      pendingAmount: oCalculatedPendingAmount,
    }));
  }, [allPayablePledges]);

  // Calculate current and total due amounts
  useEffect(() => {
    if (deductiblePledges.length > 0 && nonDeductiblePledges.length > 0) {
      const oCalculatedCurrentDeductible =
        calculateCurrentDuePayablePledges(deductiblePledges);
      const oCalculatedCurrentNonDeductible =
        calculateCurrentDuePayablePledges(nonDeductiblePledges);
      const oCalculatedTotalDeductible =
        calculateTotalBalancePayablePledges(deductiblePledges);
      const oCalculatedTotalNonDeductible =
        calculateTotalBalancePayablePledges(nonDeductiblePledges);
      setAmounts((prevAmounts: PledgePaymentAmounts) => ({
        ...prevAmounts,
        currentDeductible: oCalculatedCurrentDeductible,
        currentNonDeductible: oCalculatedCurrentNonDeductible,
        totalDeductible: oCalculatedTotalDeductible,
        totalNonDeductible: oCalculatedTotalNonDeductible,
      }));
    } else if (allPayablePledges.length > 0) {
      const oCalculatedCurrentDue =
        calculateCurrentDuePayablePledges(allPayablePledges);
      const oCalculatedTotalBalance =
        calculateTotalBalancePayablePledges(allPayablePledges);

      setAmounts((prevAmounts) => ({
        ...prevAmounts,
        currentDue: oCalculatedCurrentDue,
        totalBalance: oCalculatedTotalBalance,
      }));
    }
  }, [allPayablePledges, deductiblePledges, nonDeductiblePledges]);

  return amounts;
};

export default usePledgePaymentAmounts;
