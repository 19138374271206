import React, { useState } from 'react';

import ConstituentAvatar, { aSizes } from 'components/ConstituentAvatar';
import { SkeletonCircle, SkeletonText } from 'components/LoadingZone/Skeleton';
import LoadingZone from 'components/LoadingZone';
import usePageTitle from 'utils/hooks/usePageTitle';
import Highlight, { aHighlightFlavors } from 'components/Layout/Highlight';
import LongTextWithToggle from 'components/Layout/LongTextWithToggle';
import Button from 'components/Buttons/Button';
import Modal from 'components/Layout/Modal/Modal';
import InfoButtonWithModal from 'components/Layout/InfoButtonWithModal';
import PageLayout from '../Layout/PageLayout';
import Tooltip from '../Layout/Tooltip';
import Accordion from '../Layout/Accordion';
import FormFields from './FormFields';

const oAvatarData = {
  sLookupId: '8-10856344',
  sFirstName: 'Tripp',
  sLastName: 'Hodges',
};

const aModalFlavors = ['message', 'alert', 'sign-in'];

const ComponentLibrary = () => {
  const [sActiveModal, fnSetActiveModal] = useState(null);
  usePageTitle('Component Library');
  return (
    <PageLayout sTitle='Component Library'>
      <h2>Constituent Avatars</h2>
      <div className='componentLibrary__avatars'>
        {aSizes.map((sSize) => (
          <ConstituentAvatar
            lookupId={oAvatarData.sLookupId}
            sFirstName={oAvatarData.sFirstName}
            sLastName={oAvatarData.sLastName}
            bHasPhoto={false}
            sSize={sSize}
            key={sSize}
          />
        ))}
      </div>
      <div className='componentLibrary__avatars'>
        {aSizes.map((sSize) => (
          <ConstituentAvatar
            lookupId={oAvatarData.sLookupId}
            sFirstName={oAvatarData.sFirstName}
            sLastName={oAvatarData.sLastName}
            bHasPhoto
            sSize={sSize}
            key={sSize}
          />
        ))}
      </div>

      <h2>Loading Indicators</h2>
      <div className='styleGuide__loadingSection'>
        <div className='styleGuide__skeletonCircles'>
          <SkeletonCircle size='extraLarge' />
          <SkeletonCircle size='large' />
          <SkeletonCircle size='small' />
        </div>
        <SkeletonText size='large' width='80%' />
        <SkeletonText size='medium' width='60%' />
        <SkeletonText size='small' width='40%' />
      </div>
      <LoadingZone isLoading />

      <h2>Tooltips</h2>
      <Tooltip
        sTriggerIcon='help'
        ariaId='component-library-tooltip'
        position='top'
      >
        Top tooltip
      </Tooltip>
      <Tooltip
        sTriggerIcon='help'
        ariaId='component-library-tooltip'
        position='bottom'
      >
        Bottom tooltip
      </Tooltip>

      <h2>Accordions</h2>
      <Accordion mTitle='Large Accordion' sTitleIcon='user' sSize='large'>
        <LoadingZone isLoading />
      </Accordion>
      <Accordion mTitle='Medium Accordion' sSize='medium'>
        <p>Peekaboo</p>
      </Accordion>
      <Accordion mTitle='Small Accordion' sSize='small'>
        <p>Peekaboo</p>
      </Accordion>
      <Accordion mTitle='Small Underline Accordion' sSize='smallUnderline'>
        <p>Peekaboo</p>
      </Accordion>

      <h2>Form Fields</h2>
      <FormFields />

      <h2>Highlights</h2>
      {aHighlightFlavors.map((sFlavor) => (
        <Highlight sFlavor={sFlavor} key={sFlavor}>
          Hi! I am a/n {sFlavor} flavor highlight.
        </Highlight>
      ))}

      <LongTextWithToggle sText='Long text with toggle. Chocolate cake dragée pie candy cake carrot cake pastry liquorice. Candy cheesecake toffee pastry pudding liquorice dessert. Cake tart pie marzipan chocolate. Lollipop donut carrot cake cheesecake bear claw pudding. Tootsie roll croissant tart powder donut pudding tootsie roll. Cookie toffee sweet roll ice cream topping bonbon jelly tootsie roll tart. Cookie topping marshmallow caramels muffin bonbon donut. Cake croissant lollipop cotton candy macaroon marshmallow icing. Macaroon pudding lollipop pudding candy danish pudding jelly cheesecake. Marzipan danish jelly-o marshmallow pie cake. Icing chocolate bar chupa chups gummi bears oat cake. Dessert tootsie roll cupcake icing chocolate.' />

      <h2>Modals</h2>
      <div className='styleGuide__buttons'>
        {aModalFlavors.map((sFlavor) => (
          <Button
            sFlavor={sFlavor}
            fnHandleClick={() => fnSetActiveModal(sFlavor)}
            key={`modal ${sFlavor}`}
          >
            Open {sFlavor} modal
          </Button>
        ))}
        <InfoButtonWithModal modalText='Hello world' />
      </div>

      {sActiveModal && (
        <Modal
          sPortalId='app'
          fnSetShowModal={fnSetActiveModal}
          sFlavor={sActiveModal}
          sModalTitle='A delightful modal title'
          sModalSubtitle={
            sActiveModal === 'alert'
              ? 'More title but of the sub variety'
              : null
          }
          sModalDetail='Shiver me timbers hearties doubloon fluke topsail mutiny lugsail
              salmagundi Sea Legs careen.'
        >
          <Button
            sFlavor='primary'
            fnHandleClick={() => fnSetActiveModal(null)}
          >
            Yes
          </Button>
        </Modal>
      )}
    </PageLayout>
  );
};

export default ComponentLibrary;
