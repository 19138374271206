import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import qs from 'qs';

import Accordion from 'components/Layout/Accordion';
import CheckoutTracker from 'components/Checkout/CheckoutTracker';
import PageLayout from 'components/Layout/PageLayout';
import {
  currentUserLookupIdSelector,
  currentUserNicknameSelector,
} from 'redux/selectors';
import oWnApi from 'utils/WnApi';
import LoadingZone from 'components/LoadingZone';
import Button from 'components/Buttons/Button';
import { retrieveFormConfig } from '../helpers';
import * as CheckoutActions from '../redux/actions';

/**
 * Responsible for checkout confirmation page.
 *
 * @param {object} props - The props passed to this component.
 * @param {string} props.sTitle - The title to be displayed.
 * @param {string} props.sFirstStepLabel - The label to user on the tracker for the 'select' step.
 * @param {boolean} props.bHasPayment - Whether there is a payment step.
 */
const CheckoutConfirmation = ({
  sTitle,
  sFirstStepLabel,
  oTableMarkup,
  bHasPayment,
}) => {
  const sNickName = useSelector(currentUserNicknameSelector);
  const sLookupId = useSelector(currentUserLookupIdSelector);
  const oCheckoutData = useSelector((state) => state.Checkout);
  const [bIsLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();
  const oLocation = useLocation();
  const fnNavigate = useNavigate();
  const sLocationSearch = oLocation.search.replace('?', '');
  const oPaymentQuery = qs.parse(sLocationSearch);
  const oFormConfig = retrieveFormConfig(oLocation);
  const sThankYouFor = oFormConfig.language.thankYouFor;
  const sCustomMessage = oFormConfig.language.customMessage;
  const sPaymentType = oFormConfig.language.paymentType;
  const sReturnToLinkUrl = oFormConfig.returnLink.url;
  const sReturnToLinkLabel = oFormConfig.returnLink.label;
  const sContactName = oFormConfig.contact.name;
  const sContactEmail = oFormConfig.contact.email;
  const sContactPhone = oFormConfig.contact.phone;
  const sContactPhoneNoDots = sContactPhone.replace(/\./gm, '');
  const sPaymentAmount = `$${oPaymentQuery.transaction_total}`;
  const sConstituentEmail = oPaymentQuery.email;
  const sTransactionId = oPaymentQuery.transid;
  const sDadTransactionId = oPaymentQuery.dad_transaction_id;

  useEffect(() => {
    const fnCompleteTransaction = async () => {
      const oRequestData = {
        dadtransactionid: sDadTransactionId,
        trustcommercetransactionid: sTransactionId,
      };
      const oResponse = await oWnApi.patch(
        `constituents/${sLookupId}/philanthropy/gift`,
        oRequestData
      );
      if (oResponse.status === 200) {
        setIsLoading(false);
      } else {
        setIsLoading(false);
        console.error(
          `Failed to complete gift transaction.\n\n${oResponse.status}: ${oResponse.data}`
        );
      }
    };

    if (sLookupId && sDadTransactionId) {
      fnCompleteTransaction();
    }

    return () => {
      dispatch(CheckoutActions.reset({}));
    };
  }, [sLookupId, sTransactionId, sDadTransactionId, dispatch]);

  useEffect(() => {
    if (!oCheckoutData.dadTransactionId) {
      setIsLoading(false);
    }
  }, [oCheckoutData.dadTransactionId]);

  const fnHandleReturnButtonClick = () => {
    dispatch(CheckoutActions.reset({}));
    fnNavigate(sReturnToLinkUrl);
  };

  return (
    <>
      <CheckoutTracker
        sFirstStepLabel={sFirstStepLabel}
        sCurrentStep='confirmation'
        bHasPayment={bHasPayment}
      />
      <PageLayout sTitle={sTitle}>
        <h2 className='checkoutConfirmation__title'>Confirmation</h2>
        <LoadingZone isLoading={bIsLoading}>
          <>
            <div className='checkoutConfirmation__textWrapper'>
              <p className='checkoutConfirmation__text'>
                Thank you, {sNickName}, for {sThankYouFor}! An email
                confirmation for your {sPaymentAmount} {sPaymentType} has been
                sent to {sConstituentEmail}.
              </p>
              {sCustomMessage}
            </div>

            <div className='checkoutConfirmation__transactionSummary'>
              <h3 className='checkoutConfirmation__transaction'>
                Transaction Confirmation:{' '}
                <span className='checkoutConfirmation__transactionId'>
                  #{sTransactionId}
                </span>
              </h3>
              <Accordion
                mTitle='Payment Summary'
                className='checkoutConfirmation__summary'
                sTitleIcon='bookOpen'
              >
                <div className='checkoutConfirmation__summaryContent'>
                  {oTableMarkup}
                </div>
              </Accordion>
            </div>
            <Button
              fnHandleClick={fnHandleReturnButtonClick}
              className='checkoutConfirmation__done'
            >
              Return to {sReturnToLinkLabel}
            </Button>
            <div className='checkoutConfirmation__textWrapper'>
              <p className='checkoutConfirmation__text'>
                If you have any questions, please contact {sContactName} at{' '}
                <a
                  href={`mailto:${sContactEmail}`}
                  className='checkoutConfirmation__contactInfo'
                >
                  {sContactEmail}
                </a>{' '}
                or{' '}
                <a
                  href={`tel:${sContactPhoneNoDots}`}
                  className='checkoutConfirmation__contactInfo'
                >
                  {sContactPhone}
                </a>
                .
              </p>
            </div>
          </>
        </LoadingZone>
      </PageLayout>
    </>
  );
};

CheckoutConfirmation.propTypes = {
  sTitle: PropTypes.string.isRequired,
  sFirstStepLabel: PropTypes.string.isRequired,
  oTableMarkup: PropTypes.node.isRequired,
  bHasPayment: PropTypes.bool.isRequired,
};

export default CheckoutConfirmation;
