import React from 'react';
import DateAndTime from 'date-and-time';

import Accordion from 'components/Layout/Accordion';
import { oCurrencyFormatter, truthyDadValues } from 'utils/common';
import InstallmentSchedule from './InstallmentSchedule';
import InstallmentsPaid from './InstallmentsPaid';
import { fnGroupByBalance, pledgePropType } from './helpers';

/**
 * Displays info about an open pledge.
 */
const Pledge = ({ oPledgeData }) => {
  const sNextInstallmentDate = DateAndTime.format(
    new Date(oPledgeData.NEXTINSTALLMENTDUEDATE),
    'MM.DD.YY'
  );
  const oInstallmentsByBalance = fnGroupByBalance(oPledgeData.INSTALLMENTS);

  return (
    <Accordion
      mTitle={
        <div className='pledge__title'>
          <div>Commitment #{oPledgeData.pledgeNumber}</div>
          <div className='pledge__nextInstallment'>
            Next Installment {sNextInstallmentDate}
          </div>
        </div>
      }
      className='pledge'
      sSize='medium'
      sGtmCategory='Pledge'
    >
      <div className='pledge__content'>
        {oInstallmentsByBalance.Unpaid && (
          <InstallmentSchedule
            aData={oInstallmentsByBalance.Unpaid}
            isDeductible={truthyDadValues.includes(oPledgeData.IS_DEDUCTIBLE)}
          />
        )}

        {oInstallmentsByBalance.Paid && (
          <InstallmentsPaid
            aData={oInstallmentsByBalance.Paid}
            sPledgeNumber={oPledgeData.LOOKUPID}
          />
        )}

        <div className='pledge__initialAmountWrapper'>
          <span className='pledge__initialAmountLabel'>
            Initial Commitment Amount
          </span>{' '}
          <span className='pledge__initialAmount'>
            {oCurrencyFormatter.format(oPledgeData.AMOUNT)}
          </span>
        </div>
        <div className='pledge__balanceWrapper'>
          <span>Remaining Balance Due</span>{' '}
          <span>{oCurrencyFormatter.format(oPledgeData.BALANCE)}</span>
        </div>
        <p className='pledge__pending'>
          *Balance does not reflect pending payments
        </p>
      </div>
    </Accordion>
  );
};

Pledge.propTypes = {
  oPledgeData: pledgePropType.isRequired,
};

export default Pledge;
