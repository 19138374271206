import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';

import CheckoutTracker from 'components/Checkout/CheckoutTracker';
import PageLayout from 'components/Layout/PageLayout';
import oWnApi from 'utils/WnApi';
import { currentUserLookupIdSelector } from 'redux/selectors';
import LoadingZone from 'components/LoadingZone';
import Button from 'components/Buttons/Button';
import { fnElementRefPropType } from 'utils/customPropTypes';
import { oCurrencyFormatter } from '../../../utils/common';
import CheckoutEmails from './CheckoutEmails';
import CheckoutAddresses from './CheckoutAddresses';
import { getSelectStepLink } from '../helpers';
import CheckoutSummaryTable from '../CheckoutSummaryTable';
import * as CheckoutActions from '../redux/actions';
import { fnValidateContactInfo, fnMakeContactInfoPrimary } from './helpers';

/**
 * Responsible for checkout review page.
 *
 * @param {object} props - The props passed to this component.
 * @param {string} props.sTitle - The title to be displayed.
 * @param {string} props.sFirstStepLabel - The label to user on the tracker for the 'select' step.
 * @param {object} props.oHiddenFieldsMarkup - The HTML for the hidden fields.
 *    Used to submit data to the payment step (which is outside React).
 * @param {boolean} props.bHasPayment - Whether there is a payment step.
 * @param {object} props.oHiddenFieldFormRef - Reference to HiddenFieldForm.
 *
 * @author Elizabeth Hicks <hicksem@wfu.edu>
 * @copyright 2021 Wake Forest University, AIT
 */
const CheckoutReview = ({
  sTitle,
  sFirstStepLabel,
  oHiddenFieldsMarkup,
  bHasPayment,
  oHiddenFieldFormRef,
  oDadTransactionData,
}) => {
  const oLocation = useLocation();
  const sSelectStepLink = getSelectStepLink(2, oLocation);
  const oContactInfoRef = useRef();
  const [oErrors, setErrors] = useState({
    email: '',
    address: '',
    postGiftToDad: '',
  });
  const [bIsSubmitting, setIsSubmitting] = useState(false);
  const sLookupId = useSelector(currentUserLookupIdSelector);
  const aCheckoutSummaryItems = useSelector(
    (state) => state.Checkout.checkoutSummaryItems
  );
  const fCheckoutTotal = useSelector((state) => state.Checkout.checkoutTotal);
  const sCheckoutDadTransactionId = useSelector(
    (state) => state.Checkout.dadTransactionId
  );
  const dispatch = useDispatch();

  // If a user comes back to this page from the payment page,
  // the browser loads the page from the back/forward cache (See https://web.dev/bfcache/).
  // That means it retains the React state, so we need to reset `bIsSubmitting`.
  useEffect(() => {
    const fnHandleReloadFromBackForwardCache = (oEvent) => {
      if (oEvent.persisted) {
        setIsSubmitting(false);
      }
    };
    window.addEventListener('pageshow', fnHandleReloadFromBackForwardCache);

    return () => {
      window.removeEventListener(
        'pageshow',
        fnHandleReloadFromBackForwardCache
      );
    };
  }, []);

  const fnHandleContinueClick = async () => {
    setIsSubmitting(true);

    const bIsContactInfoValid = fnValidateContactInfo(setErrors);
    if (!bIsContactInfoValid) {
      oContactInfoRef.current.scrollIntoView({
        block: 'nearest',
        behavior: 'smooth',
      });
      setIsSubmitting(false);
      return;
    }

    fnMakeContactInfoPrimary();

    // Generate a new DAD transaction ID every time the user clicks 'Continue',
    // because they may have changed their donation amounts or designations.
    if (sCheckoutDadTransactionId) {
      dispatch(CheckoutActions.update({ dadTransactionId: '' }));
    }

    const oResponse = await oWnApi.post(
      `constituents/${sLookupId}/philanthropy/gift`,
      oDadTransactionData
    );
    if (oResponse.status === 200) {
      dispatch(
        CheckoutActions.update({
          dadTransactionId: oResponse.data.ID,
        })
      );
    } else {
      setIsSubmitting(false);
      console.error(
        `Failed to POST gift transaction data to DAD.\n\n${oResponse.status}: ${oResponse.data}`
      );
      setErrors((oPrevErrors) => ({
        ...oPrevErrors,
        postGiftToDad:
          'Something went wrong. Please refresh the page and try again.',
      }));
    }
  };

  // Submit the form to the payment gateway when the DAD transaction ID is available.
  useEffect(() => {
    if (bIsSubmitting && sCheckoutDadTransactionId) {
      oHiddenFieldFormRef.current.submit();
    }
  }, [sCheckoutDadTransactionId, bIsSubmitting, oHiddenFieldFormRef]);

  return (
    <>
      <CheckoutTracker
        sFirstStepLabel={sFirstStepLabel}
        sCurrentStep='review'
        bHasPayment={bHasPayment}
      />
      <PageLayout sTitle={sTitle}>
        <h2 className='checkoutReview__title'>Review</h2>
        <LoadingZone isLoading={bIsSubmitting}>
          <>
            <p className='checkoutReview__instructions'>
              Please review your information below to ensure we communicate
              appropriately with you. You may provide updates if necessary.
            </p>

            <h3 className='checkoutReview__subTitle' ref={oContactInfoRef}>
              Your Contact Information
            </h3>
            <CheckoutEmails oErrors={oErrors} setErrors={setErrors} />
            <CheckoutAddresses oErrors={oErrors} setErrors={setErrors} />

            <h3 className='checkoutReview__subTitle'>Payment Summary</h3>
            <div className='checkoutReview__summary checkout__sectionBox'>
              <CheckoutSummaryTable aCheckoutItems={aCheckoutSummaryItems} />
              <p className='checkoutReview__total t-paragraph--medium'>
                <span>Total:</span>
                <span>{oCurrencyFormatter.format(fCheckoutTotal)}</span>
              </p>
            </div>

            {oErrors.postGiftToDad && (
              <p className='checkoutReview__dadPostError form__overallError'>
                {oErrors.postGiftToDad}
              </p>
            )}

            <div className='checkout__cancelContinue'>
              <Button
                fnHandleClick={fnHandleContinueClick}
                bIsLoading={bIsSubmitting}
                className='checkout__continue'
              >
                Continue
              </Button>
              <Link
                className='checkout__cancel button--secondary'
                to={sSelectStepLink}
              >
                Back
              </Link>
            </div>
          </>
        </LoadingZone>

        {oHiddenFieldsMarkup}
      </PageLayout>
    </>
  );
};

CheckoutReview.defaultProps = {
  oHiddenFieldsMarkup: {},
  bHasPayment: true,
  oHiddenFieldFormRef: {},
  oDadTransactionData: null,
};

CheckoutReview.propTypes = {
  sTitle: PropTypes.string.isRequired,
  sFirstStepLabel: PropTypes.string.isRequired,
  oHiddenFieldsMarkup: PropTypes.node,
  bHasPayment: PropTypes.bool,
  oHiddenFieldFormRef: fnElementRefPropType,
  oDadTransactionData: PropTypes.shape({
    ONLINEPLEDGEPAYMENTCOMMENT: PropTypes.string,
    PLEDGES: PropTypes.arrayOf(
      PropTypes.shape({
        AMOUNT: PropTypes.string,
        DESIGNATIONS: PropTypes.arrayOf(
          PropTypes.shape({
            AMOUNT: PropTypes.string,
            CODE: PropTypes.string,
          })
        ),
        ID: PropTypes.string,
      })
    ),
    TOTAL_TRANS_AMOUNT: PropTypes.number,
  }),
};

export default CheckoutReview;
