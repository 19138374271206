import React, { useEffect, useMemo } from 'react';
import { useFormikContext, Field } from 'formik';

import { Radio, TextArea, DollarAmountTextField } from 'components/Form/Fields';
import InfoButtonWithModal from 'components/Layout/InfoButtonWithModal';
import { buildSetAmountRadioButtonsData } from './helpers';
import usePledgePaymentAmounts from './usePledgePaymentAmounts';

const SelectPaymentFormBody = () => {
  const oFormikContext = useFormikContext();
  const amounts = usePledgePaymentAmounts();

  // Clear other amount and note fields when user chooses current or total amount due
  useEffect(
    () => {
      const bIsCurrentOrTotal =
        oFormikContext.values.paymentType &&
        oFormikContext.values.paymentType !== 'other';
      const bHasOtherDetails =
        oFormikContext.values.otherAmount || oFormikContext.values.note;

      if (bIsCurrentOrTotal && bHasOtherDetails) {
        oFormikContext.setFieldValue('otherAmount', '');
        oFormikContext.setFieldValue('note', '');
      }
    },
    // oFormikContext changes on EVERY render -__-, so it's useless as a dependency.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [oFormikContext.values.paymentType, oFormikContext.values.otherAmount]
  );

  // Auto-select "Other" if user enters an other amount.
  useEffect(
    () => {
      const { otherAmount, paymentType } = oFormikContext.values;
      if (otherAmount !== '' && paymentType !== 'other') {
        oFormikContext.setFieldValue('paymentType', 'other');
      }
    },
    // oFormikContext changes on EVERY render -__-, so it's useless as a dependency.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [oFormikContext.values.otherAmount, oFormikContext.values.paymentType]
  );

  // Sync hidden amount field with amount user chose
  useEffect(
    () => {
      const { amount, paymentType } = oFormikContext.values;
      if (!paymentType) {
        return;
      }
      const sOtherAmountWithoutCommas =
        oFormikContext.values.otherAmount.replace(/,/g, '');

      if (paymentType === 'other') {
        if (amount !== sOtherAmountWithoutCommas) {
          oFormikContext.setFieldValue('amount', sOtherAmountWithoutCommas);
        }
      } else if (paymentType && amount !== amounts[paymentType]?.value) {
        oFormikContext.setFieldValue('amount', amounts[paymentType]?.value);
      }
    },
    // oFormikContext changes on EVERY render -__-, so it's useless as a dependency.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      oFormikContext.values.paymentType,
      oFormikContext.values.otherAmount,
      amounts,
    ]
  );

  const aSetAmountRadioButtonsData = useMemo(
    () => buildSetAmountRadioButtonsData(amounts),
    [amounts]
  );

  const hasBothDeductibleAndNonDeductible =
    ((amounts.currentDeductible?.value ?? 0) > 0 &&
      (amounts.currentNonDeductible?.value ?? 0) > 0) ||
    ((amounts.totalDeductible?.value ?? 0) > 0 &&
      (amounts.totalNonDeductible?.value ?? 0) > 0);

  return (
    <>
      <fieldset>
        <div className='selectPaymentForm__paymentAmountLegendWrapper'>
          <legend>Payment Amount</legend>
          {hasBothDeductibleAndNonDeductible && (
            <InfoButtonWithModal modalText='Making a singular payment for both deductible and non-deductible commitments is not recommended. Please consult your tax advisor for more information.' />
          )}
        </div>
        {aSetAmountRadioButtonsData.map((oRadioButtonData) => (
          <Radio
            key={oRadioButtonData.id}
            sId={oRadioButtonData.id}
            sLabel={oRadioButtonData.label}
            sName='paymentType'
            mValue={oRadioButtonData.value}
            sWrapperClassName='selectPaymentForm__radio'
            bIsDisabled={
              oRadioButtonData.isDisabled || amounts.pendingAmount?.value > 0
            }
            bSilenceErrors
          />
        ))}
        <div className='selectPaymentForm__radioWrapper--other'>
          <Radio
            sId='amount-other'
            sLabel='Other'
            sName='paymentType'
            mValue='other'
            sWrapperClassName='selectPaymentForm__radio'
            bSilenceErrors
          />
          <DollarAmountTextField
            sId='other-dollar-amount'
            sLabel='Other amount'
            sName='otherAmount'
            sClassName='selectPaymentForm__otherAmountField'
            bIsLabelHidden
          />
        </div>
        {oFormikContext.values.paymentType === 'other' && (
          <TextArea
            sId='payment-note'
            sLabel='Note'
            sName='note'
            className='selectPaymentForm__noteField'
            sHelpText='Please share how you would like us to apply your payment.'
          />
        )}
      </fieldset>
      {oFormikContext.touched.paymentType &&
        oFormikContext.errors.paymentType && (
          <p className='field__errorMessage'>
            {oFormikContext.errors.paymentType}
          </p>
        )}
      <Field type='hidden' name='amount' />
    </>
  );
};

export default SelectPaymentFormBody;
