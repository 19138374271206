import { createSelector } from 'reselect';
import { falseyDadValues, truthyDadValues } from 'utils/common';

export const selectPayablePledges = (state: any) =>
  state.PledgePayment.payablePledges || [];

export const selectDeductiblePledges = createSelector(
  selectPayablePledges,
  (pledges) =>
    pledges.filter((pledge: any) =>
      truthyDadValues.includes(pledge.IS_DEDUCTIBLE)
    )
);

export const selectNonDeductiblePledges = createSelector(
  selectPayablePledges,
  (pledges) =>
    pledges.filter((pledge: any) =>
      falseyDadValues.includes(pledge.IS_DEDUCTIBLE)
    )
);
