import React from 'react';
import PropTypes from 'prop-types';

import InfoButtonWithModal from 'components/Layout/InfoButtonWithModal';
import Installment from './Installment';
import { installmentPropType } from '../helpers';

/**
 * Displays list of upcoming pledge installments.
 */
const InstallmentSchedule = ({ aData, isDeductible }) => {
  const bHasMultipleDesignations = aData[0].DESIGNATIONS.length > 1;
  return (
    <div className='installmentSchedule'>
      <div className='installmentSchedule__titleWrapper'>
        <p className='installmentSchedule__title'>Installment Schedule</p>
        {!isDeductible && (
          <InfoButtonWithModal modalText='Pursuant to the Tax Cuts and Jobs Act of 2017, payments made to the University that result in the right to purchase seating for athletic events in a Wake Forest stadium are not deductible as charitable contributions. The commitments listed below are considered by Wake Forest to be related to the right to purchase seating. We encourage you to review the implications of changes in the tax law with your financial and/or tax advisor.' />
        )}
      </div>
      <ul className='installmentSchedule__installments'>
        {aData.map((oInstallment) => (
          <li
            className={`installmentSchedule__installment ${
              bHasMultipleDesignations
                ? 'installmentSchedule__installment--multiDesignation'
                : ''
            }`}
            key={oInstallment.ID}
          >
            <Installment oData={oInstallment} />
          </li>
        ))}
      </ul>
    </div>
  );
};

InstallmentSchedule.propTypes = {
  aData: PropTypes.arrayOf(installmentPropType).isRequired,
  isDeductible: PropTypes.bool.isRequired,
};

export default InstallmentSchedule;
