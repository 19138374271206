import { PledgePaymentAmounts } from './usePledgePaymentAmounts';

type RadioButtonData = {
  id: string;
  label: string;
  value: string;
  isDisabled: boolean;
};

/**
 * Generates the data for the radio buttons that allow the user to select the amount to pay.
 *
 * @param amounts - From `usePledgePaymentAmounts`
 * @returns {RadioButtonData[]}
 */
export const buildSetAmountRadioButtonsData = (
  amounts: PledgePaymentAmounts
): RadioButtonData[] => {
  const radioButtonsData: RadioButtonData[] = [];

  const createRadioButtonData = (
    id: string,
    label: string,
    value: keyof PledgePaymentAmounts
  ): RadioButtonData => ({
    id,
    label: `${label} ${amounts[value]?.format()}`,
    value: value as string,
    isDisabled: amounts[value]?.value === 0,
  });

  const hasBothDeductibleAndNonDeductible =
    ((amounts.currentDeductible?.value ?? 0) > 0 &&
      (amounts.currentNonDeductible?.value ?? 0) > 0) ||
    ((amounts.totalDeductible?.value ?? 0) > 0 &&
      (amounts.totalNonDeductible?.value ?? 0) > 0);

  if (hasBothDeductibleAndNonDeductible) {
    radioButtonsData.push(
      createRadioButtonData(
        'amount-current-deductible',
        'Current Deductible Due',
        'currentDeductible'
      )
    );
    radioButtonsData.push(
      createRadioButtonData(
        'amount-current-non-deductible',
        'Current Non-Deductible Due',
        'currentNonDeductible'
      )
    );
    radioButtonsData.push(
      createRadioButtonData(
        'amount-total-deductible',
        'Total Deductible Due',
        'totalDeductible'
      )
    );
    radioButtonsData.push(
      createRadioButtonData(
        'amount-total-non-deductible',
        'Total Non-Deductible Due',
        'totalNonDeductible'
      )
    );
  } else {
    radioButtonsData.push(
      createRadioButtonData('amount-current-due', 'Current Due', 'currentDue')
    );
    radioButtonsData.push(
      createRadioButtonData('amount-total-due', 'Total Due', 'totalBalance')
    );
  }

  return radioButtonsData;
};

export default {};
