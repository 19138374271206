import * as R from 'ramda';

import { calculateMonetarySumByKey } from 'utils/common';
import {
  determineFiscalYearEndDate,
  retrieveDueUnpaidPledgeInstallments,
  retrieveUnpaidPledgeInstallments,
} from '../../PledgePaymentCheckout/helpers/calculateSelectPaymentTotals';

const oFiscalYearEndDate = determineFiscalYearEndDate();

/**
 * Retrieves designations for selected installments to pay.
 *
 * @param {array} aSelectedInstallments - Installments selected to be paid.
 *
 * @example of aSelectedInstallments structure
 *  [
 *    // Installment #1
 *    {
 *      ID: '9313B561-EC4C-4ECE-9E32-C0C8D0D6CCD0',
 *      DESIGNATIONS: [
 *      {
 *          NAME: 'The Wake Forest Fund',
 *          CODE: 'UFU01',
 *          AMOUNT: '1000.000',
 *        },
 *      ]
 *    },
 *    // Installment #2
 *    {
 *      ID: '0A13B561-EC4C-4ECE-9E32-C0C8D0D6CCD0',
 *      DESIGNATIONS: [
 *        {
 *          NAME: 'The Wake Forest Fund',
 *          CODE: 'UFU01',
 *          AMOUNT: '1000.000',
 *        },
 *      ]
 *    }
 *  ]
 *
 * @example of return based on aSelectedInstallments
 *  [
 *    {
 *      NAME: 'The Wake Forest Fund',
 *      CODE: 'UFU01',
 *      AMOUNT: '1000.000',
 *    },
 *    {
 *      NAME: 'The Wake Forest Fund',
 *      CODE: 'UFU01',
 *      AMOUNT: '1000.000',
 *    },
 *  ]
 *
 * @returns {array}
 */
const retrieveDesignationsForSelectedInstallments = (aSelectedInstallments) => {
  let aDesignationsForSelectedInstallments = [];
  aSelectedInstallments.forEach((oInstallment) => {
    const aInstallmentDesignations = oInstallment.DESIGNATIONS;
    aDesignationsForSelectedInstallments = [
      ...aDesignationsForSelectedInstallments,
      ...aInstallmentDesignations,
    ];
  });
  return aDesignationsForSelectedInstallments;
};

const groupByDesignationCode = R.groupBy(R.prop('CODE'));

/**
 * Reduces designations for selected installments to pledge.
 *
 * Designation should only be listed one time for the pledge
 * and designation total should reflect the total for each of
 * the installments that are being paid with that designation.
 *
 * @param {array} aDesignationsForSelectedInstallments
 *
 * @example of aDesignationsForSelectedInstallments structure
 *  [
 *    {
 *      NAME: 'The Wake Forest Fund',
 *      CODE: 'UFU01',
 *      AMOUNT: '1000.000',
 *    },
 *    {
 *      NAME: 'The Wake Forest Fund',
 *      CODE: 'UFU01',
 *      AMOUNT: '1000.000',
 *    },
 *  ]
 *
 * @example returns array of objects like this based on the example for
 * aSelectedInstallmentsDesignations
 *  {
 *    NAME: 'The Wake Forest Fund',
 *    CODE: 'UFU01',
 *    AMOUNT: 2000,
 *  }
 *
 * @returns {array}
 */
const reduceDesignationsFromInstallmentsToPledge = (
  aDesignationsForSelectedInstallments
) => {
  const oGroupedByDesignationCode = groupByDesignationCode(
    aDesignationsForSelectedInstallments
  );
  const aDesignationsForPledge = Object.keys(oGroupedByDesignationCode).map(
    (sDesignationCode) => {
      const aDesignations = oGroupedByDesignationCode[sDesignationCode];
      const oDesignationTotal = calculateMonetarySumByKey(
        aDesignations,
        'AMOUNT'
      );
      return {
        ...aDesignations[0],
        AMOUNT: oDesignationTotal.value,
      };
    }
  );
  return aDesignationsForPledge;
};

/**
 * Retrieve the designations data for specified pledge.
 *
 * Need to add all the designations up for all the installments.
 * If current - use current unpaid installments
 * If total - use all unpaid installments
 *
 * @param {object} oPayablePledge - Data for a specified payable pledge.
 * @param {string} sPaymentType - Select type of payment ('current', 'total', 'other').
 *
 * @example of return array based on example aPayablePledges.
 *  [
 *    {
 *      NAME: 'The Wake Forest Fund',
 *      CODE: 'UFU01',
 *      AMOUNT: 2000
 *    },
 *    {
 *      NAME: 'The Wake Forest Fund - School of Business',
 *      CODE: 'SBU01',
 *      AMOUNT: 800
 *    }
 *  ]
 *
 * @returns {array};
 */
const retrievePledgeDesignations = (oPayablePledge, sPaymentType) => {
  let aSelectedInstallments = [];
  if (sPaymentType.includes('current')) {
    aSelectedInstallments = retrieveDueUnpaidPledgeInstallments(
      oPayablePledge,
      oFiscalYearEndDate
    );
  } else if (sPaymentType.includes('total')) {
    aSelectedInstallments = retrieveUnpaidPledgeInstallments(oPayablePledge);
  }
  const aDesignationsForSelectedInstallments =
    retrieveDesignationsForSelectedInstallments(aSelectedInstallments);
  return reduceDesignationsFromInstallmentsToPledge(
    aDesignationsForSelectedInstallments
  );
};

/**
 * Builds the transaction data for 'current' or 'total' payment types.
 *
 * @param {object[]} aPayablePledges - API data for all payable pledges.
 * @param {string} sPaymentType - Select type of payment ('current', 'total', 'other').
 *
 * @example of return array based on example aPayablePledges from buildReviewData.
 *  [
 *    {
 *      pledgeGuid: 'B84D454B-143F-4485-8FB1-2970AEDD9778',
 *      pledgeNumber: '11887783',
 *      amount: 1200,
 *      designations: [
 *        {
 *          CODE: 'UFU01',
 *          NAME: 'The Wake Forest Fund',
 *          AMOUNT: 500,
 *        },
 *        {
 *          CODE: 'SBU01',
 *          NAME: 'The Wake Forest Fund - School of Business',
 *          AMOUNT: 400,
 *        },
 *        {
 *          CODE: 'LSU01',
 *          NAME: 'The Wake Forest Fund - School of Law',
 *          AMOUNT: 300,
 *        },
 *      ]
 *    }
 *  ]
 *
 * @returns {array}
 */
const buildPledgesTransactionData = (aPayablePledges, sPaymentType) =>
  aPayablePledges.map((oPayablePledge) => {
    const aPledgeDesignationsData = retrievePledgeDesignations(
      oPayablePledge,
      sPaymentType
    );
    const oPledgeAmount = calculateMonetarySumByKey(
      aPledgeDesignationsData,
      'AMOUNT'
    );
    return {
      pledgeGuid: oPayablePledge.ID,
      pledgeNumber: oPayablePledge.pledgeNumber.toString(),
      amount: oPledgeAmount.value,
      designations: aPledgeDesignationsData,
    };
  });

/**
 * Builds the data to pass to the review step for checkout summary.
 *
 * Uses the form values and calculated values to build an array of data ready
 * to be passed to the review step.
 *
 * @param {object} oParams - Has keys: aFormValues, aPayablePledges
 *    oFormValues - Values from the SelectPaymentForm.
 *    aPayablePledges - API data for all payable pledges.
 *
 * @example of oParams
 * One pledge with 1 installment due with multiple designations.
 * {
 *   oFormValues: {
 *     amount: 1000,
 *     paymentType: 'current',
 *     otherAmount: 0,
 *     note: '',
 *   },
 *   aPayablePledges: [
 *     {
 *       ID: 'B84D454B-143F-4485-8FB1-2970AEDD9778',
 *       BALANCE: 6000,
 *       pledgeNumber: 11887782,
 *       INSTALLMENTS: [
 *         {
 *           ID: '7D8F64D5-612D-4885-90FC-80FE6921727E',
 *           DUE_DATE: '2022-06-30',
 *           AMOUNT: 1200,
 *           BALANCE: 1200,
 *           APPLIED: 0,
 *           DESIGNATIONS: [
 *             {
 *               NAME: 'The Wake Forest Fund',
 *               CODE: 'UFU01',
 *               AMOUNT: 500
 *             },
 *             {
 *               NAME: 'The Wake Forest Fund - School of Business',
 *               CODE: 'SBU01',
 *               AMOUNT: 400
 *             },
 *             {
 *               NAME: 'The Wake Forest Fund - School of Law',
 *               CODE: 'LSU01',
 *               AMOUNT: 300
 *             }
 *           ]
 *         }
 *       ]
 *     }
 *   ]
 * }
 *
 * @example of return object based on example of aPayablePledges.
 * This assumes one pledge as listed above, and user selected pay current due.
 *  {
 *    amount: 1200,
 *    pledges: [
 *      {
 *        pledgeGuid: 'B84D454B-143F-4485-8FB1-2970AEDD9778',
 *        pledgeNumber: '11887783',
 *        amount: 1200,
 *        designations: [
 *          {
 *            CODE: 'UFU01',
 *            NAME: 'The Wake Forest Fund',
 *            AMOUNT: 500,
 *          },
 *          {
 *            CODE: 'SBU01',
 *            NAME: 'The Wake Forest Fund - School of Business',
 *            AMOUNT: 400,
 *          },
 *          {
 *            CODE: 'LSU01',
 *            NAME: 'The Wake Forest Fund - School of Law',
 *            AMOUNT: 300,
 *          },
 *        ]
 *      }
 *    ]
 *  }
 *
 * @see buildBatchTransactionData.testData.js for more examples of incoming values and
 *  expected return.
 *
 * @returns {object}
 */
export const buildBatchTransactionData = ({
  oFormValues,
  aPayablePledges,
  aDeductiblePledges,
  aNonDeductiblePledges,
}) => {
  const sPaymentType = oFormValues.paymentType ? oFormValues.paymentType : '';
  const aTransactionData = {
    amount: parseFloat(oFormValues.amount),
    pledges: [],
    note: '',
  };
  switch (sPaymentType) {
    case 'currentDue':
    case 'totalBalance':
      aTransactionData.pledges = buildPledgesTransactionData(
        aPayablePledges,
        sPaymentType
      );
      break;
    case 'currentDeductible':
    case 'totalDeductible':
      aTransactionData.pledges = buildPledgesTransactionData(
        aDeductiblePledges,
        sPaymentType
      );
      break;
    case 'currentNonDeductible':
    case 'totalNonDeductible':
      aTransactionData.pledges = buildPledgesTransactionData(
        aNonDeductiblePledges,
        sPaymentType
      );
      break;
    case 'other':
      aTransactionData.note = oFormValues.note;
      break;
    default:
      console.error(
        "Expected payment type: 'current', 'total' or 'other'.",
        sPaymentType
      );
      break;
  }

  return aTransactionData;
};

export default buildBatchTransactionData;
